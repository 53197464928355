<template>
    <el-container>
      <!--头 -->
      <el-header>
        <div>
          <img src="../assets/logo.png" alt="" class="im"/>
          <span>后台管理系统</span>
        </div>
        <el-button type="info" @click="logout">退出</el-button>
    </el-header>
      <el-container>
        <!-- 侧边栏-->
        <el-aside width="200px">
          <el-menu class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b">
          <el-submenu index="1">
            <!-- 一级菜单 -->
            <template slot="title">
              <i class="el-icon-document"></i>
              <span>文章管理</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item index="1-1">
              <template slot="title">
                <i class="el-icon-document"></i>
                <span>文章列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
        </el-aside>
        <!-- 内容区-->
        <el-main>
          <el-button @click="test()">测试接口</el-button>
        </el-main>
      </el-container>
    </el-container>
</template>
<script type="text/javascript">
  export default {
         methods:{
           logout:function(){
               sessionStorage.removeItem("access_token");
               sessionStorage.removeItem("refresh_token");
               this.$router.push("/login");
           },
           test:function(){
                this.$http.post("teacher/index").then(res=>{
                   let data = res.data;
                   if(data.code!=0){
                     this.$message({message:data.msg,type:'error'})
                     return;
                   }

                });
            }
         }
  }
</script>

<style type="text/css" scoped>

  .el-container{
    width:100%;
    height:100%;
  }
  .el-header{
    display: flex;
    justify-content:space-between;
    background:rgb(55,55,55);
    align-items: center;
  }
  .el-header div{
     display: flex;
     align-items: center;
  }
  .el-header div span{
    color:#fff;
  }
  .im{
    width:40px;
  }
  .el-aside{
    background:rgb(84,92,100);
  }
</style>
